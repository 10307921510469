import React from "react"
import Fade from "react-reveal/Fade"

import "./GalleryText.scss"
const GalleryText = ({ paragraph }) => {
  return (
    <section className="gallery__container">
      <div className="gallery__container__left">
        <Fade bottom distance="40px">
          <div className="gallery__container__text">{paragraph.first}</div>
        </Fade>
      </div>
      <div className="gallery__container__right">
        <Fade bottom distance="40px">
          <div className="gallery__container__text-paragraph">
            {paragraph.second}
          </div>
        </Fade>
      </div>
    </section>
  )
}

export default GalleryText
