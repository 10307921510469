import React, { Component } from "react"
import Img from "gatsby-image"
import axios from "axios"
import Fade from "react-reveal/Fade"
import Rellax from "rellax"

import GalleryModal from "../gallery/GalleryModal"

import "./GalleryImages.scss"

class GalleryImages extends Component {
  state = {
    showModal: false,
    activeItem: {},
    items: [],
  }

  componentDidMount() {
    this.getVideoData()

    const rellax = new Rellax(".rellax", {
      center: true,
      wrapper: null,
      vertical: true,
      horizontal: false,
    })
  }

  getVideoData = async () => {
    const { gallery } = this.props
    let { items } = this.state

    for (let i = 0; i < gallery.length; i++) {
      for (let j = 0; j < gallery[i].row.length; j++) {
        let obj = undefined

        if (gallery[i].row[j].video) {
          let response = await axios.get(
            "https://noembed.com/embed?url=" + gallery[i].row[j].video
          )
          obj = response.data
        }

        items.push(obj)
      }
    }

    this.setState({ items })
  }

  render() {
    let { showModal, activeItem, items } = this.state
    const { gallery } = this.props
    let gc = -1

    const openModal = (item) => () => {
      document.querySelector("body").classList.add("model-open")
      this.setState({ showModal: true })

      axios
        .get("https://noembed.com/embed?url=" + item.video)
        .then((response) => {
          item.embed = response.data.html
          this.setState({ activeItem: item })
        })
    }

    const closeModal = () => {
      document.querySelector("body").classList.remove("model-open")
      this.setState({ showModal: false, activeItem: {} })
    }

    const names = [
      "gallery-images__container__one-layout",
      "gallery-images__container__two-layout",
      "gallery-images__container__three-layout",
    ]

    return (
      <>
        <GalleryModal
          showModal={showModal}
          toggleModal={closeModal}
          data={activeItem}
        />
        <section className="gallery-images">
          <div className="gallery-images__container">
            {gallery &&
              gallery.map((el, i) => {
                return (
                  <div className={names[el.row.length - 1]} key={i}>
                    {el.row.map((item, j) => {
                      gc++

                      if (item.video) {
                        return (
                          <Fade bottom>
                            <div
                              className="image-wrap has-video"
                              key={j}
                              onClick={openModal(item)}
                            >
                              <div>
                                <img
                                  className="rellax"
                                  data-rellax-speed="-0.7"
                                  src={
                                    item.image
                                      ? item.image.localFile.publicURL
                                      : items[gc] && items[gc].thumbnail_url
                                  }
                                  alt={items[gc] && items[gc].title}
                                />
                              </div>
                              <Fade bottom>
                                <span>{items[gc] && items[gc].title}</span>
                              </Fade>
                            </div>
                          </Fade>
                        )
                      }

                      if (item.image) {
                        return (
                          <Img
                            key={j}
                            fluid={item.image.localFile.childImageSharp.fluid}
                            alt={item.image.altText}
                          />
                        )
                      }

                      return null
                    })}
                  </div>
                )
              })}
          </div>
        </section>
      </>
    )
  }
}

export default GalleryImages
