import React from "react"

import GalleryText from "./GalleryText"
import GalleryImages from "./GalleryImages"

import "./Gallery.scss"

const Gallery = (props) => {
  return (
    <section className="property__gallery">
      <GalleryText {...props} />
      <GalleryImages {...props} />
    </section>
  )
}

export default Gallery
