import React, { useState, useEffect } from "react"

import "./hero.scss"
const Hero = ({ heroVideoUrl, heroVideoPlaceholder }) => {
  const [showVideo, setShowVideo] = useState(true)

  const handleScroll = () => {
    if (showVideo === true && window.scrollY >= window.innerHeight) {
      setShowVideo(false)
    } else {
      setShowVideo(true)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <section className="property__hero is-fullheight">
      <video
        className={showVideo ? "video" : "video__hidden"}
        playsInline
        muted
        autoPlay
        loop
        src={heroVideoUrl}
        poster={
          heroVideoPlaceholder &&
          heroVideoPlaceholder.localFile.childImageSharp.original.src
        }
      />
    </section>
  )
}

export default Hero
