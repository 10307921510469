import React, { useEffect } from "react"
import Img from "gatsby-image"
import axios from "axios"
import Fade from "react-reveal/Fade"

import GalleryModal from "../gallery/GalleryModal"

import PlayIcon from "../../assets/images/play-icon.svg"
import "./PageIntro.scss"

const PageIntro = ({
  introHeading,
  introSubheading,
  introVideo,
  introImage,
}) => {
  const [showModal, setShowModal] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState({})
  const [showPlayIcon, setShowPlayIcon] = React.useState(false)

  const checkWidth = () => {
    if (window.innerWidth <= 900) {
      setShowPlayIcon(true)
    } else {
      setShowPlayIcon(false)
    }
  }

  useEffect(() => {
    checkWidth()
    window.addEventListener("resize", checkWidth)

    return () => {
      window.removeEventListener("resize", checkWidth)
    }
  }, [])

  const openModal = (item) => () => {
    document.querySelector("body").classList.add("model-open")
    setShowModal(true)

    axios
      .get("https://noembed.com/embed?url=" + item.video)
      .then((response) => {
        item.embed = response.data.html
        setActiveItem(item)
      })
  }

  const closeModal = () => {
    document.querySelector("body").classList.remove("model-open")
    setShowModal(false)
    setActiveItem({})
  }

  return (
    <>
      <GalleryModal
        showModal={showModal}
        toggleModal={closeModal}
        data={activeItem}
      />
      <section className="page-intro">
        <div className="page-intro__container">
          <Fade bottom distance="40px">
            <div className="page-intro__container__text">
              <div
                className="page-intro__heading"
                dangerouslySetInnerHTML={{ __html: introHeading }}
              />
              <div
                className="page-intro__subheading"
                dangerouslySetInnerHTML={{ __html: introSubheading }}
              />
            </div>
            <div
              onClick={openModal({ video: introVideo })}
              className="page-intro__click"
            >
              <Img
                className="page-intro__image"
                fluid={introImage && introImage.localFile.childImageSharp.fluid}
                alt={introImage && introImage.altText}
              />
              <div
                className={
                  "play-icon" + (showPlayIcon ? " play-icon_show" : "")
                }
              >
                <img src={PlayIcon} alt="Play Icon" />
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </>
  )
}

export default PageIntro
