import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import he from "he"

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import BottomBar from "../components/BottomBar"

import Hero from "../components/sector/Hero"
import PageIntro from "../components/sector/PageIntro"
import Gallery from "../components/sector/Gallery"

const Sector = ({ data }) => {
  const page = data.wpSector

  return (
    <>
      <SEO
        path={`/sector/${page.slug}/`}
        title={he.decode(page.seo.title)}
        metaDescription={he.decode(page.seo.metaDesc)}
      />
      <Header />

      <Hero {...page.acf} />
      <div className="sector__content">
        <PageIntro {...page.acf} />
        <Gallery {...page.acf} />
      </div>
      <Footer />
      <BottomBar />
    </>
  )
}

Sector.propTypes = {
  data: PropTypes.object,
}

Sector.defaultProps = {
  data: null,
}

export const pageQuery = graphql`
  query($id: String!) {
    wpSector(id: { eq: $id }) {
      title
      slug
      seo {
        title
        metaDesc
      }
      acf {
        description

        paragraph {
          first
          second
        }

        gallery {
          row {
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            video
            caption
          }
        }

        heroVideoUrl
        heroVideoPlaceholder {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        introHeading
        introSubheading
        introVideo
        introImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default Sector
